// Libs.
import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
// Deps.
import AppLayout from '../../components/layout/AppLayout';
import SEO from '../../components/layout/seo';

import appAliases from '../../data/appAliases';

import UserLoginMobile from '../../components/user/user-login-mobile';
import CustomerAccountSidebar from '../../components/customer/customer-sidebar';
import CustomerView from '../../components/customer/customer-view';
import CustomerPointsView from '../../components/customer/customer-points-view';

import styles from '../../components/user/user-login.module.scss';
import { useStateValue } from '../../store/state';
import { ProfileMenuContext } from '../../components/layout/member-layout';
import CustomerTyrCards from '../../components/customer/customer-tyr-cards';
import OrderHistory from '../../components/order-history/order-history';
import NavigationDrawer, { useDrawer } from '../../hooks/useNavigationDrawer';

import CustomerUpdate from '../../components/customer/customer-update';
import locale from '../../locale/locale';
import UserRegister from "../../components/user/user-register";
import AppRewardsAddCard from "../../components/app/app-rewards-add-card";
import navigate from '../../libs/navigate';
import UserResetPasswordConfirmed from "../../components/user/user-reset-password-confirmed";
import { getViewingPlatform } from '../../libs/getViewingPlatform';


const AccountProfilePage = () => {
  const { iOS, tallDevice } = getViewingPlatform();

  const [state] = useStateValue();
  const [drawers, setDrawer] = useDrawer();
  const [userLoggedIn, setUserLoggedIn] = useState(!get(state, 'user.access_token', false));
  const [isRegisterDrawer, setRegisterDrawer] = useState(false);
  const changePasswordPageData = {
    ...locale.changePasswordPage,
  };
  const changeDrawer = (item) => {
    navigate(appAliases.appAccountProfile, { state: { section: item } });
  };
  const drawer = {
    setRegisterDrawer,
    isRegisterDrawer,
  };

  return (
    <AppLayout updatePoints={true}>
      <SEO title="User Login" />

      {!userLoggedIn && (
        <>
          {iOS && <div style={{ height: 40, backgroundColor: '#f8f4ed' }}></div>}
          <CustomerAccountSidebar
            ProfileMenuContext={ProfileMenuContext}
            drawer={{ changeDrawer: changeDrawer }}
          />
        </>
      )}

      <NavigationDrawer header={''} drawerStatus={userLoggedIn} onClose={setUserLoggedIn}>
        {iOS && tallDevice && <div style={{ height: 40, backgroundColor: '#f8f4ed' }}></div>}
        <UserLoginMobile drawer={{doLogin: setUserLoggedIn, doRegister: setRegisterDrawer}}/>
      </NavigationDrawer>

      <NavigationDrawer header={'Account'} drawerStatus={drawers.includes('points')}>
        <CustomerPointsView />
      </NavigationDrawer>

      <NavigationDrawer header={'Account'} drawerStatus={drawers.includes('cards')}>
        <CustomerTyrCards />
      </NavigationDrawer>

      <NavigationDrawer header={'Account'} drawerStatus={drawers.includes('addCard')}>
        <AppRewardsAddCard isAccountMenu={true} />
      </NavigationDrawer>

      <NavigationDrawer header={'Account'} drawerStatus={drawers.includes('personalInfo')}>
        <CustomerView />
      </NavigationDrawer>

      <NavigationDrawer
        header={'Personal Information'}
        drawerStatus={drawers.includes('personalInfoUpdate')}
      >
        {iOS && tallDevice && <div style={{ height: 40, backgroundColor: '#f8f4ed' }}></div>}
        <CustomerUpdate />
      </NavigationDrawer>

      <NavigationDrawer header={'Account'} drawerStatus={drawers.includes('transactionHistory_classes')}>
        {iOS && tallDevice && <div style={{ height: 40, backgroundColor: '#f8f4ed' }}></div>}
        <OrderHistory type="groceries" />
      </NavigationDrawer>

      <NavigationDrawer header={'Account'} drawerStatus={isRegisterDrawer} onClose={setRegisterDrawer}>
        {iOS && tallDevice && <div style={{ height: 40, backgroundColor: '#f8f4ed' }}></div>}
        <UserRegister drawer={drawer} onClose={setRegisterDrawer} currentPage={"account-profile"}/>
      </NavigationDrawer>

      <NavigationDrawer
        header={'Account'}
        drawerStatus={drawers.includes('changePassword')}
      >
        {iOS && tallDevice && <div style={{ height: 40, backgroundColor: '#f8f4ed' }}></div>}
        <UserResetPasswordConfirmed type={'update'} pageData={changePasswordPageData} cancel={setDrawer} />
      </NavigationDrawer>
    </AppLayout>
  );
};

export default AccountProfilePage;
