import React, { useContext } from 'react';
import { Link } from 'gatsby';

import { useStateValue } from '../../store/state';
import locale, { accountProfilePage } from '../../locale/locale.json';
import styles from './customer-profile.module.scss';
import pageAliases from '../../data/pageAliases';
import { ProfileMenuBack } from '../layout/member-layout';
import AppHidden from '../app/common/app-hidden';
import AppShown from '../app/common/app-shown';
import { AppContext } from '../layout/AppLayout';
import { useDrawer } from '../../hooks/useNavigationDrawer';
import navigate from '../../libs/navigate';

const CustomerProfile = () => {
  const [state] = useStateValue();
  const isApp = useContext(AppContext);
  const [, navigateDrawer] = useDrawer();
  return (
    <>
      <AppHidden>
        <ProfileMenuBack title={accountProfilePage.titles.pageTitleOuter}/>
      </AppHidden>
      <div className={styles.customerProfileContentContainer} data-app={isApp}>
        <AppShown>
          <div className={styles.customerProfileHeaderTitleContainer} data-app={isApp}>
            <div className={styles.customerProfilePageMainTitle}>
              {accountProfilePage.titles.pageTitleOuter}
            </div>
          </div>
        </AppShown>

        <div className={styles.customerProfileHeaderTitleContainer}>
          <div className={styles.customerProfilePageTitle}>
            {accountProfilePage.titles.pageTitle}
          </div>
          <AppShown>
            <button
              className={styles.customerProfileCustomerDetailsEdit}
              onClick={() => {
                navigateDrawer('personalInfoUpdate');
              }}
            >
              {accountProfilePage.buttons.editProfileButton}
            </button>
          </AppShown>
          <AppHidden>
            <div className={styles.customerProfileUpdateLink}>
              <Link to={pageAliases.accountProfileUpdate}>
                {accountProfilePage.buttons.editProfileButton}
              </Link>
            </div>
          </AppHidden>
        </div>
        <div className={styles.customerProfileCustomerDetailsLine}>
          <div className={styles.customerProfileCustomerDetailsCell}>
            <div className={styles.customerProfileCustomerDetailsTitleContainer}>
              <div className={styles.customerProfileCustomerDetailsTitle}>
                {locale.form.firstName}
              </div>
            </div>
            <div className={styles.customerProfileCustomerDetailsInfo}>
              {state.customer.FirstName}
            </div>
          </div>
          <div className={styles.customerProfileCustomerDetailsCell}>
            <div className={styles.customerProfileCustomerDetailsTitleContainer}>
              <div className={styles.customerProfileCustomerDetailsTitle}>
                {locale.form.lastName}
              </div>
            </div>
            <div className={styles.customerProfileCustomerDetailsInfo}>
              {state.customer.LastName}
            </div>
          </div>
        </div>
        <div className={styles.customerProfileCustomerDetailsLine}>
          <div className={styles.customerProfileCustomerDetailsCell}>
            <div className={styles.customerProfileCustomerDetailsTitle}>{locale.form.email}</div>
            <div className={styles.customerProfileCustomerDetailsInfo}>
              {state.customer.EmailAddress}
            </div>
            <div className={styles.customerProfileCustomerDetailsFooter}>
              {accountProfilePage.labels.emailAddressFooter}
            </div>
          </div>
          <div className={styles.customerProfileCustomerDetailsCell}>
            <div className={styles.customerProfileCustomerDetailsTitleContainer}>
              <div className={styles.customerProfileCustomerDetailsTitle}>{locale.form.phone}</div>
            </div>
            <div className={styles.customerProfileCustomerDetailsInfo}>
              {state.customer.PrimaryPhone}
            </div>
          </div>
        </div>
        <div className={styles.customerProfileCustomerDetailsLine}>
          <div className={styles.customerProfileCustomerDetailsCell}>
            <div className={styles.customerProfileCustomerDetailsTitleContainer}>
              <div className={styles.customerProfileCustomerDetailsTitle}>
                {locale.form.address}
              </div>
            </div>
            <div className={styles.customerProfileCustomerDetailsInfo}>
              {state.customer.PrimaryAddress_Street1} {state.customer.PrimaryAddress_Street2}
              <br/>
              {state.customer.PrimaryAddress_City} {state.customer.PrimaryAddress_Province}
              <br/>
              {state.customer.PrimaryAddress_PostalCode}
              <br/>
            </div>
          </div>
        </div>
        <div className={styles.customerProfileCustomerPass} data-app={isApp}>
          <div className={styles.customerProfileCustomerDetailsPassCell}>
            <div className={styles.customerProfileCustomerDetailsPassContainer}>
              <div className={styles.customerProfileCustomerDetailsTitle}>
                {locale.form.password}
              </div>
              <AppShown>
                <button
                  className={styles.customerProfileCustomerDetailsEdit}
                  onClick={() => {
                    navigateDrawer('changePassword');
                  }}
                >
                  {accountProfilePage.buttons.editProfileButton}
                </button>
              </AppShown>
              <AppHidden>
                <div className={styles.customerProfileUpdateLink}>
                  <Link to={pageAliases.accountChangePassword}>
                    {accountProfilePage.buttons.editProfileButton}
                  </Link>
                </div>
              </AppHidden>
            </div>
            <div>••••••••••</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerProfile;
