// Libs
import React, { useContext, useEffect } from 'react';
// State
import useCustomer from '../../hooks/useCustomer';
import { useStateValue } from '../../store/state';
// Deps
import CustomerProfile from './customer-profile';
import CustomerEmailPreferences from './customer-email-preferences';
// Assets
import styles from './customer-view.module.scss';
import { AppContext } from '../layout/AppLayout';
import { useDrawer } from '../../hooks/useNavigationDrawer';

const CustomerView = () => {
  const [state] = useStateValue();
  const { getCustomer } = useCustomer();
  const isApp = useContext(AppContext);

  useEffect(() => {
    getCustomer();
  }, [state.user.access_token]);

  return (
    <div className={styles.customerViewContainer} data-app={isApp}>
      <CustomerProfile />
      <CustomerEmailPreferences />
    </div>
  );
};

export default CustomerView;
