// Libs
import React, { useContext, useEffect } from 'react';
// State
import useCustomer from '../../hooks/useCustomer';
import { useStateValue } from '../../store/state';
// Deps
import CustomerEditProfile from './customer-edit-profile';
// Assets
import styles from './customer-update.module.scss';
import { AppContext } from '../layout/AppLayout';

const CustomerUpdate = () => {
  const [state] = useStateValue();
  const { getCustomer } = useCustomer();
  const isApp = useContext(AppContext);
  // Load latest customer data.
  useEffect(() => {
    getCustomer();
  }, [state.user.access_token]);

  return (
    <div className={styles.customerUpdateContainer} data-app={isApp}>
      <CustomerEditProfile />
    </div>
  );
};

export default CustomerUpdate;
