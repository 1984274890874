import React, { useContext } from 'react';

import { useStateValue } from '../../store/state';
import { accountProfilePage } from '../../locale/locale.json';
import styles from './customer-email-preferences.module.scss';

import FormCheckBox from '../common/check-box';
import crmService from '../../services/crmClientService';
import { CustomerData } from '../../store/customer/action-creators';
import { AppContext } from '../layout/AppLayout';

const CustomerEmailPreferences = () => {
  const [state, dispatch] = useStateValue();
  const { EmailPreferences } = state.customer;
  const permissions = EmailPreferences.split(';');

  const app = useContext(AppContext);

  let [, LONGOS_TYR] = permissions.filter((per) => per.indexOf('TYR') > -1)[0].split(':');
  let [, LONGOS_ERECEIPT] = permissions.filter((per) => per.indexOf('ERECEIPT') > -1)[0].split(':');
  let [, LONGOS_FLYER] = permissions.filter((per) => per.indexOf('FLYER') > -1)[0].split(':');

  const addZeroPadding = (val) => {
    if (parseInt(val) < 10) {
      return `0${parseInt(val)}`;
    } else {
      return val;
    }
  };
  const toggleAnnouncements = async () => {
    const d = new Date();
    LONGOS_FLYER = LONGOS_FLYER === 'True' ? 'False' : 'True';
    const message = `{"EmailPreferences": "LONGOS_FLYER:${LONGOS_FLYER}:${d.getFullYear()}${addZeroPadding(
      d.getMonth() + 1
    )}${addZeroPadding(d.getDate())};"}`;
    const updatedCustomer = await crmService.customerUpdate(
      state.user.CRMCustomerID,
      message,
      state.user.access_token
    );
    dispatch(CustomerData(updatedCustomer));
  };

  const toggleReceipts = async () => {
    const d = new Date();
    LONGOS_ERECEIPT = LONGOS_ERECEIPT === 'True' ? 'False' : 'True';
    const message = `{"EmailPreferences": "LONGOS_ERECEIPT:${LONGOS_ERECEIPT}:${d.getFullYear()}${addZeroPadding(
      d.getMonth() + 1
    )}${addZeroPadding(d.getDate())};"}`;
    const updatedCustomer = await crmService.customerUpdate(
      state.user.CRMCustomerID,
      message,
      state.user.access_token
    );
    dispatch(CustomerData(updatedCustomer));
  };

  const toggleTYR = async () => {
    const d = new Date();
    LONGOS_TYR = LONGOS_TYR === 'True' ? 'False' : 'True';
    const message = `{"EmailPreferences": "LONGOS_TYR:${LONGOS_TYR}:${d.getFullYear()}${addZeroPadding(
      d.getMonth() + 1
    )}${addZeroPadding(d.getDate())};"}`;
    const updatedCustomer = await crmService.customerUpdate(
      state.user.CRMCustomerID,
      message,
      state.user.access_token
    );
    dispatch(CustomerData(updatedCustomer));
  };

  return (
    <>
      <div className={styles.customerEmailPreferencesHeaderTitleContainer} data-app={app}>
        <div className={styles.customerEmailPreferencesPageTitle}>
          {accountProfilePage.titles.emailPrefs}
        </div>
      </div>

      <div className={styles.customerEmailPreferencesCustomerDetailsLine} data-app={app}>
        <div className={styles.customerEmailPreferencesPageSubtitle}>
          {accountProfilePage.titles.emailPrefsSubtitle}
        </div>
      </div>

      <div className={styles.customerEmailPreferencesCustomerDetailsLine}  data-app={app}>
        <div className={styles.customerEmailPreferencesCustomerDetailsCell} data-app={app}>
          <FormCheckBox
            id="longosEmails"
            onChange={() => toggleAnnouncements()}
            isChecked={LONGOS_FLYER === 'True'}
            label={accountProfilePage.labels.longosEmails}
            fullLine={false}
            checkboxStyle="red"
            lableTextStyle="normal"
          />
        </div>
        <div className={styles.customerEmailPreferencesCustomerDetailsCell}  data-app={app}>
          <FormCheckBox
            id="receiptEmails"
            onChange={() => toggleReceipts()}
            isChecked={LONGOS_ERECEIPT === 'True'}
            label={accountProfilePage.labels.receiptEmails}
            fullLine={false}
            checkboxStyle="red"
            lableTextStyle="normal"
          />
        </div>
      </div>
      <div className={styles.customerEmailPreferencesCustomerDetailsLine}  data-app={app}>
      <div className={styles.customerEmailPreferencesCustomerDetailsCell} data-app={app}>
          <FormCheckBox
            id="thankyouEmails"
            onChange={() => toggleTYR()}
            isChecked={LONGOS_TYR === 'True'}
            label={accountProfilePage.labels.thankYouEmails}
            fullLine={false}
            checkboxStyle="red"
            lableTextStyle="normal"
          />
        </div>
      </div>
    </>
  );
};

export default CustomerEmailPreferences;
