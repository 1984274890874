import React, {useContext, useState} from 'react';
//State
import { useStateValue } from '../../store/state';
import { CloseModal, OpenModal } from '../../store/modal/action-creators';
import {modalTypes} from '../../store/modal/action-types';
import { uiBodyScrollable } from '../../store/ui/action-creators';
import AppHidden from '../app/common/app-hidden';
import AppShown from '../app/common/app-shown';
import AppPrivacyPolicies from '../app/app-privacy-policies';
import formValidators from '../../libs/form/form-validator';
import FormInput from '../common/form-input';
import FormCheckBox from '../common/check-box';
import ButtonLoader from '../common/loaders/button-loader';
import locale from '../../locale/locale.json';
import {useDrawer} from "../../hooks/useNavigationDrawer";
import { redirectKeyPressHandler } from '../../libs/accessibility';
import {replacePatternWithComponent} from '../../libs/localeUtils';

import styles from './user-consent-modal.module.scss';

const UserConsentModal = ({onClose, emailAddress}) => {
    const [state, dispatch] = useStateValue();
    const [, closeDrawer] = useDrawer();
    const [EmailAddress, setEmailAddress] = useState({ value: emailAddress.value, error: false });
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptTermsError, setAcceptTermsError] = useState(false);

    const [submission, setSubmission] = useState(false);


    const closeModal = () => {
        // Set active if callback was provided.
        onClose && onClose(acceptTerms ? true : false);
        dispatch(CloseModal());
        dispatch(uiBodyScrollable());
    };

    const handleSubmit = () => {
      if(!acceptTerms) {
        setAcceptTermsError(true);
        return;
      }
      setAcceptTermsError(false);
      setSubmission(true);
      setTimeout(() => {
        closeModal();
      }, 3000);
    }

    const openPrivacyPoliciesModal = () => {
      dispatch(
        OpenModal({
          id: 'privacy-policies',
          type: modalTypes.SLIDEIN,
          content: <AppPrivacyPolicies />,
        })
      );
    };
    
    const pattern = /(Policies)/;
    const openModal = replacePatternWithComponent(
      locale.registrationPage.consentModal.appAcceptTermsLabel,
      pattern,
      <span onClick={openPrivacyPoliciesModal} onKeyPress={redirectKeyPressHandler(openPrivacyPoliciesModal)}>
        Policies
      </span>
    );

    return (
        <div className={styles.userConsentModalOverlay}>
          <div className={styles.userConsentModalContainer}>
            {!submission && (<div>
                <span
                role="button"
                tabIndex="0"
                onClick={closeModal}
                onKeyPress={redirectKeyPressHandler(closeModal)}
                className={styles.userConsentModalClose}
              />
            </div>)}
            {submission && (<div>
              <h1 className={`${styles.userConsentModalTitle} ${styles.successTitle}`}>
                                {locale.registrationPage.consentModal.successTitle}
              </h1>
              <h1 className={`${styles.userConsentModalTitle} ${styles.successTitle} ${styles.hidden}`}>
                                {locale.registrationPage.consentModal.successTitle}
              </h1>
              <p className={styles.userConsentModalSubtitle}>{locale.registrationPage.consentModal.successSubTitle}</p>
            </div>)}
            {!submission && (<div>
              <h1 className={styles.userConsentModalTitle}>
                {locale.registrationPage.consentModal.termsTitle}
              </h1>
              <h1 className={`${styles.userConsentModalTitle} ${styles.hidden}`} dangerouslySetInnerHTML={{
                      __html:locale.registrationPage.consentModal.appTermsTitle}}>
              </h1>
              <p className={styles.userConsentModalSubtitle} dangerouslySetInnerHTML={{
                      __html:locale.registrationPage.consentModal.termsSubTitle}}></p>
              <div className={styles.userConsentModalContent}>
                <p>But wait, there's more!</p>
                <p>• &nbsp;&nbsp;&nbsp;Be the first to hear about special events</p>
                <p>• &nbsp;&nbsp;&nbsp;Receive Longo’s flyer & weekly specials</p>
                <p>• &nbsp;&nbsp;&nbsp;Earn exclusive promotions and offers</p>
                <p>• &nbsp;&nbsp;&nbsp;Track your E-receipts</p>
              </div>
              <AppHidden>
                <div className={styles.userConsentModalFormCheckbox}>
                  <FormCheckBox
                    id="acceptConsentTermsLabel"
                    onChange={() => {
                      setAcceptTerms(!acceptTerms);
                    }}
                    isChecked={acceptTerms}
                    label={locale.registrationPage.consentModal.acceptTermsLabel}
                    fullLine={false}
                    checkboxStyle="red"
                  />
                  {acceptTermsError && (
                      <div className={styles.userConsentModalFormCheckboxError}>
                        {locale.registrationPage.consentModal.acceptTermsError}
                      </div>
                    )}
                </div>
              </AppHidden>
              <AppShown>
                <div className={styles.appUserRegisterFormOptInContainer}>
                  <FormCheckBox
                    id="acceptConsentTermsLabel"
                    onChange={() => {
                      setAcceptTerms(!acceptTerms);
                    }}
                    // isComponent={true}
                    isChecked={acceptTerms}
                    label={locale.registrationPage.consentModal.appAcceptTermsLabel}
                    // label={openModal}
                    fullLine={false}
                    checkboxStyle="red"
                  />
                  {acceptTermsError && (
                    <div className={styles.userConsentModalFormCheckboxError}>
                      {locale.registrationPage.consentModal.acceptTermsError}
                    </div>
                  )}
                </div>
              </AppShown>
              <div className={styles.userConsentModalFormLineContainer}>
                <div className={styles.userConsentModalFormLineHalf}>
                  <FormInput
                    placeholder={locale.form.email}
                    value={EmailAddress.value}
                    onChange={(e) =>
                      formValidators.email(e.target.value, EmailAddress, setEmailAddress)
                    }
                    disabled={true}
                    error={EmailAddress.error}
                    errorMsg={EmailAddress.error && locale.form.emailError}
                  />
                </div>
                <AppHidden>
                  <button className={styles.userConsentModalSubmit} onClick={() => handleSubmit()} >
                    {locale.registrationPage.consentModal.submitDesktopButton}
                    {submission && <ButtonLoader />}
                  </button>
                </AppHidden>
                <AppShown>
                  <button className={styles.userConsentModalSubmit} onClick={() => handleSubmit()} >
                    {locale.registrationPage.consentModal.submitButton}
                    {submission && <ButtonLoader />}
                  </button>
                </AppShown>
              </div>
              </div>)}
          </div>
        </div>
    );
}

export default UserConsentModal;
