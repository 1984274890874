// Libs.
import React, { useState, useEffect } from 'react';
// Deps.
import NewMembersMobile from './new-members-mobile';
import UserLoginForm from './user-login-form';
import AppCorporateLinks from '../app/app-corporate-links';
// Loaders.
import PagePreloader from '../common/loaders/page-preloader';
// Hooks.
import { useDrawer } from '../../hooks/useNavigationDrawer';
// Assets.
import styles from './user-login.module.scss';

const UserLoginMobile = ({ drawer: { doRegister, doLogin }, isRegistered = false }) => {
  const [submission, setSubmission] = useState(true);
  const [, navigateDrawer] = useDrawer();
  useEffect(() => {
    setSubmission(false);
  }, []);

  const onLogin = (status = true) => {
    setSubmission(status);
  };

  const onLoginComplete = () => {
    setSubmission(false);
    // Clean active userLogin drawer to prevent display if user navigates to
    // rewards or account after login.
    navigateDrawer(false);
    doLogin(false);
  };

  const onError = () => {
    setSubmission(false);
    // doLogin(false);
  };

  return (
    <div className={styles.userLoginWrapper} data-app="true">
      {submission && <PagePreloader />}
      <div className={styles.userLoginBackground} data-loader={submission} data-guest={false}>
        <div className={styles.userLoginContainer}>
          <UserLoginForm
            title="Sign In"
            onLogin={onLogin}
            onLoginComplete={onLoginComplete}
            onError={onError}
            isRegistered={isRegistered}
          />
          { !isRegistered && <>
            <NewMembersMobile onOpen={doRegister} />
            <AppCorporateLinks />
          </>}
        </div>
      </div>
    </div>
  );
};

export default UserLoginMobile;
