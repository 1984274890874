// Libs
import React, { useContext } from 'react';
// State
import { useStateValue } from '../../store/state';
import UserPointsDiagram from '../common/user-points/user-points-diagram';
// Deps
import ManageUserPoints from './modifications/manage-user-points';
import LongosAppCta from '../earn-rewards/longos-app-cta';
// Assets
import styles from './customer-points-view.module.scss';
import locale from '../../locale/locale';
import { ProfileMenuBack } from '../layout/member-layout';
import AppHidden from '../app/common/app-hidden';
import { AppContext } from '../layout/AppLayout';

const CustomerPointsView = () => {
  const app = useContext(AppContext);
  const [state] = useStateValue();
  const containerClass = app
    ? styles.customerPointsViewOffWhiteContainer
    : styles.customerPointsViewWhiteContainer;
  const titleContainerClass = app
    ? styles.customerPointsViewHeaderTitleContainerMobile
    : styles.customerPointsViewHeaderTitleContainer;
  const titleClass = app
    ? styles.customerPointsViewPageTitleMobile
    : styles.customerPointsViewPageTitle;
  return (
    <div className={containerClass}>
      <AppHidden>
        <ProfileMenuBack title={locale.accountPointsPage.pageTitle} />
      </AppHidden>
      <div className={styles.customerPointsContentContainer}>
        <div className={titleContainerClass}>
          <div className={titleClass}>{locale.accountPointsPage.pageTitle}</div>
        </div>
        <div className={styles.customerPointsViewContent}  data-app={app}>
          <UserPointsDiagram points={state.customer.PointsBalance} />
          <ManageUserPoints points={state.customer.CardBalance} />
          <AppHidden>
            <LongosAppCta
              cta={{
                title: null,
                img: null,
                summary: locale.longosAppCta.summary,
                getAppFromAppStore: locale.longosAppCta.getAppFromAppStore,
                getAppFromPlayStore: locale.longosAppCta.getAppFromPlayStore,
                googleLink: locale.longosAppCta.googleLink,
                appleLink: locale.longosAppCta.appleLink,
              }}
            />
          </AppHidden>
        </div>
      </div>
    </div>
  );
};

export default CustomerPointsView;
