// Libs
import React, {useContext} from 'react';
// Assets.
import styles from './new-members.module.scss';
import { newMembers } from '../../locale/locale.json';
import {AppContext} from "../layout/AppLayout";

const NewMembersMobile = ({onOpen = false}) => {
  const app = useContext(AppContext);
  return (
    <div className={styles.newMembersContainer} data-app={app}>
      <div className={styles.newMembersContainerMobile}>
        <span>{app ? newMembers.appSubtitleMobile : newMembers.subtitleMobile}</span><br />
        <button className={styles.newMembersLink} onClick={() => onOpen && onOpen(true)} >
          {newMembers.cta}
        </button>
      </div>
    </div>
  );
};

export default NewMembersMobile;
