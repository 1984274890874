import React, { useContext, useState } from 'react';
import { useUserPointsDataForDiagram } from '../../../libs/useUserPointsDataForDiagram';
//assets
import styles from './manage-user-points.module.scss';
import { useImageStaticQuery } from '../../../hooks/useImagesStaticQuery';
import AddMissingPoints from '../../common/add-missing-points/add-missing-points';
import AppHidden from '../../app/common/app-hidden';
import { AppContext } from '../../layout/AppLayout';
import NavigationDrawer from "../../../hooks/useNavigationDrawer";
import OrderAddMissingPoints from "../../common/add-missing-points/order-add-missing-points";
import AppShown from "../../app/common/app-shown";

const ManageUserPoints = ({ points }) => {
  const app = useContext(AppContext);
  const heartIcon = useImageStaticQuery('icons/icon-heart.svg');
  const { pointsCurrent, dollarsCurrent } = useUserPointsDataForDiagram(points);
  const [isDrawer, setDrawer] = useState(false);

  const buttonClass = app ? styles.manageUserPointsAddPointsButtonMobile : styles.manageUserPointsAddPointsButton;
  return (
    <>
      {pointsCurrent > 0 && (
        <div className={styles.manageUserPointsEarn} >
          <img src={heartIcon.publicURL} alt="icon heart" />
          <div className={styles.manageUserPointsEarnContent}>
            <div className={styles.manageUserPointsEarnContentTitle}>
              ${dollarsCurrent} earned
            </div>
            <div>Redeem your points or keep saving!</div>
          </div>
        </div>
      )}
      <div className={styles.manageUserPoints} data-mobile={app}>
        <div className={styles.manageUserPointsDetails}>
          <h2>MISSING POINTS?</h2>
          <p>
            {app && `Missing points, no worries!`} If you made a purchase at Longo’s in the last 30 days you can still claim your points by
            using our online form.
          </p>
          <AppHidden>
            <AddMissingPoints buttonClass={buttonClass} />
          </AppHidden>
          <AppShown>
            <button
              className={styles.appRewardsLandingMissingPointsButton}
              onClick={() => setDrawer(true)}
            >
              Add missing points
            </button>
            <NavigationDrawer header={'Points'} drawerStatus={isDrawer} onClose={setDrawer}>
              <OrderAddMissingPoints />
            </NavigationDrawer>
          </AppShown>
        </div>
      </div>
    </>
  );
};

export default ManageUserPoints;
