// Libs.
import React, {useState} from 'react';
import styles from './app-rewards-add-card.module.scss';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import Img from 'gatsby-image';
import { useStateValue } from '../../store/state';
import { OpenModal } from '../../store/modal/action-creators';
import { modalTypes } from '../../store/modal/action-types';
import AddReplaceCards from '../customer/customer-add-replace-cards';
import { partial } from 'lodash';
import { redirectKeyPressHandler } from '../../libs/accessibility';
import secondData from "../../mocks/app-reward-add-card";
import NavigationDrawer from "../../hooks/useNavigationDrawer";

const AppRewardsAddCard = ({ isAccountMenu = false }) => {
  const imgPlaceholder = useImageStaticQuery('earn-rewards/header-background-small.png');
  const [{ customer }, dispatch] = useStateValue();
    const [drawerStatus, setDrawerStatus] = useState(false);
  const toggle = (_action) => {
      setDrawerStatus(true);
    // dispatch(
    //   OpenModal({
    //     type: modalTypes.SLIDEIN,
    //     content: <AddReplaceCards action={_action} />,
    //   })
    // );
  };

  const handleToggleAdd = partial(toggle, 'add');

  return (
    <div className={styles.appRewardAddCardWrapper}>
      {!isAccountMenu && (
        <div>
          <Img fluid={imgPlaceholder.childImageSharp.fluid} />
        </div>
      )}
      <div className={styles.appRewardAddCardContainer} data-menu-item={isAccountMenu}>
        {!isAccountMenu && (
          <h1 className={styles.appRewardAddCardTitle}>Hi, {customer.FirstName}!</h1>
        )}
        {isAccountMenu && <h1 className={styles.appRewardAddCardTitle}>{secondData.appRewardAddCard.card}</h1>}
        <div className={styles.appRewardAddCardDescription}>{secondData.appRewardAddCard.description}</div>
        <br />
        <div className={styles.appRewardAddCardDescription}>{secondData.appRewardAddCard.descriptionSecond}</div>
        <button
          className={styles.appRewardAddCardButton}
          onClick={handleToggleAdd}
          onKeyPress={redirectKeyPressHandler(handleToggleAdd)}
        >
          {secondData.appRewardAddCard.addCard}
        </button>
      </div>
      <NavigationDrawer drawerStatus={drawerStatus} header={''} menuHide={true} close={true} onClose={setDrawerStatus}>
        <AddReplaceCards action={'add'} notDrawer={false} />
      </NavigationDrawer>
    </div>
  );
};

export default AppRewardsAddCard;
