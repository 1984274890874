import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';

import FormInput from '../common/form-input';
import formValidators from '../../libs/form/form-validator';
import crmService from '../../services/crmClientService';

import { useStateValue } from '../../store/state';
import locale, { accountProfilePage } from '../../locale/locale.json';
import styles from './customer-edit-profile.module.scss';
import { CustomerData } from '../../store/customer/action-creators';
import pageAliases from '../../data/pageAliases';
import { ProfileMenuBack } from '../layout/member-layout';
import AppHidden from '../app/common/app-hidden';
import { AppContext } from '../layout/AppLayout';
import { useDrawer } from '../../hooks/useNavigationDrawer';
import AppShown from "../app/common/app-shown";
import navigate from '../../libs/navigate';

const CustomerEditProfile = () => {
  const isApp = useContext(AppContext);
  const [, navigateDrawer] = useDrawer();
  const [state, dispatch] = useStateValue();
  const [firstName, setFirstName] = useState({
    value: state.customer.FirstName,
    error: false,
  });
  const [lastName, setLastName] = useState({
    value: state.customer.LastName,
    error: false,
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: state.customer.PrimaryPhone,
    error: false,
  });
  const [address1, setAddress1] = useState(state.customer.PrimaryAddress_Street1);
  const [address2, setAddress2] = useState(state.customer.PrimaryAddress_Street2);
  const [city, setCity] = useState(state.customer.PrimaryAddress_City);
  const [province, setProvince] = useState(state.customer.PrimaryAddress_Province);

  const [postalCode, setPostalCode] = useState({
    value: state.customer.PrimaryAddress_PostalCode,
    error: false,
  });

  const handleUpdateProfile = async () => {
    let customer = {};

    if (firstName.value === '' || firstName.error) {
      setFirstName({ value: firstName.value, error: true });
      return;
    }
    if (lastName.value === '' || lastName.error) {
      setLastName({ value: lastName.value, error: true });
      return;
    }

    if (phoneNumber.value !== '' && phoneNumber.error) {
      return;
    }

    if (postalCode.value === '' || postalCode.error) {
      setPostalCode({ value: postalCode.value, error: true });
      return;
    }
    if (firstName.value !== state.customer.FirstName) {
      customer.FirstName = firstName.value;
    }
    if (lastName.value !== state.customer.LastName) {
      customer.LastName = lastName.value;
    }
    if (phoneNumber.value !== state.customer.PrimaryPhone) {
      customer.PrimaryPhone = phoneNumber.value;
    }
    if (address1 !== state.customer.PrimaryAddress_Street1) {
      customer.PrimaryAddress_Street1 = address1;
    }
    if (address2 !== state.customer.PrimaryAddress_Street2) {
      customer.PrimaryAddress_Street2 = address2;
    }
    if (city !== state.customer.PrimaryAddress_City) {
      customer.PrimaryAddress_City = city;
    }
    if (province !== state.customer.PrimaryAddress_Province) {
      customer.PrimaryAddress_Province = province;
    }
    if (postalCode.value !== state.customer.PrimaryAddress_PostalCode) {
      customer.PrimaryAddress_PostalCode = postalCode.value;
    }
    const updateCustomer = await crmService.customerUpdate(
      state.user.CRMCustomerID,
      customer,
      state.user.access_token
    );
    dispatch(CustomerData(updateCustomer));
    if (isApp) {
      navigateDrawer(false);
    } else {
      navigate(pageAliases.accountProfile);
    }
  };

  return (
    <>
      <AppHidden>
        <ProfileMenuBack title={accountProfilePage.titles.pageTitleOuter} />
      </AppHidden>
      <div className={styles.customerProfileContentContainer}>
        <div className={styles.customerEditProfilePageMainTitle}>
          {locale.accountProfilePage.titles.pageTitleOuter}
        </div>
        <div className={styles.customerEditProfileHeaderTitleContainer}>
          <div className={styles.customerEditProfilePageTitle}>
            {locale.accountProfilePage.titles.pageTitle}
          </div>
          <div
            className={`${styles.customerEditProfileUpdateLink} ${styles.customerEditProfileUpdateLinkTop}`}
          >
            <AppHidden>
              <Link to={pageAliases.accountProfile}>
                {locale.accountProfilePage.buttons.cancelButton}
              </Link>
            </AppHidden>
            <AppShown>
              <button className={styles.customerEditProfileCancel} onClick={() => (navigateDrawer(false))}>
                {locale.accountProfilePage.buttons.cancelButton}
              </button>
            </AppShown>
          </div>
        </div>

        <div className={styles.customerEditProfileCustomerDetailsLine}>
          <div className={styles.customerEditProfileCustomerDetailsCell}>
            <div className={styles.customerEditProfileCustomerDetailsInfo}>
              <FormInput
                className={styles.customerEditProfileFormField}
                label={locale.form.firstName}
                placeholder={locale.form.firstName}
                value={firstName.value}
                onChange={(e) => formValidators.exists(e.target.value, firstName, setFirstName)}
                error={firstName.error}
                errorMsg={firstName.error ? locale.form.firstNameError : ''}
              />
            </div>
          </div>

          <div className={styles.customerEditProfileCustomerDetailsCell}>
            <div className={styles.customerEditProfileCustomerDetailsInfo}>
              <FormInput
                className={styles.customerEditProfileFormField}
                label={locale.form.lastName}
                placeholder={locale.form.lastName}
                value={lastName.value}
                onChange={(e) => formValidators.exists(e.target.value, lastName, setLastName)}
                error={lastName.error}
                errorMsg={lastName.error ? locale.form.lastNameError : ''}
              />
            </div>
          </div>
        </div>

        <div className={styles.customerEditProfileCustomerDetailsLine}>
          <div className={styles.customerEditProfileCustomerDetailsCell}>
            <div className={styles.customerEditProfileCustomerDetailsInfo}>
              <FormInput
                label={locale.form.email}
                placeholder={locale.form.email}
                className={styles.customerEditProfileFormFieldEmail}
                value={state.customer.EmailAddress}
                disabled
              />
              <div className={styles.customerEditProfileCustomerDetailsFooter}>
                {locale.accountProfilePage.labels.emailAddressFooter}
              </div>
            </div>
          </div>
          <div className={styles.customerEditProfileCustomerDetailsCell}>
            <div className={styles.customerEditProfileCustomerDetailsInfo}>
              <FormInput
                className={styles.customerEditProfileFormField}
                label={locale.form.phone}
                placeholder={locale.form.phonePlaceholder}
                value={phoneNumber.value}
                onChange={(e) => formValidators.phone(e.target.value, phoneNumber, setPhoneNumber)}
                error={phoneNumber.error}
                errorMsg={lastName.error ? locale.form.phoneError : ''}
              />
            </div>
            <div
              className={styles.customerEditProfileCustomerDetailsFooterSpacer}
              aria-hidden={true}
            >
              {locale.accountProfilePage.labels.emailAddressFooter}
            </div>
          </div>
        </div>
        <div className={styles.customerEditProfileCustomerDetailsLine}>
          <div className={styles.customerEditProfileCustomerDetailsCell}>
            <div className={styles.customerEditProfileCustomerDetailsInfo}>
              <FormInput
                className={styles.customerEditProfileFormField}
                label={locale.form.address}
                placeholder={locale.form.address}
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
                error={address1.error}
                errorMsg={address1.error ? locale.form.addressError : ''}
              />
            </div>
          </div>
          <div className={styles.customerEditProfileCustomerDetailsCell}>
            <div className={styles.customerEditProfileCustomerDetailsInfo}>
              <FormInput
                label={locale.form.address2}
                placeholder={locale.form.address2}
                className={styles.customerEditProfileFormField}
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className={styles.customerEditProfileCustomerDetailsLine}>
          <div className={styles.customerEditProfileCustomerDetailsCell}>
            <div className={styles.customerEditProfileCustomerDetailsInfo}>
              <FormInput
                className={styles.customerEditProfileFormField}
                label={locale.form.city}
                placeholder={locale.form.city}
                value={city}
                onChange={(e) => setCity(e.target.value)}
                error={city.error}
                errorMsg={city.error ? locale.form.cityError : ''}
              />
            </div>
          </div>
          <div className={styles.customerEditProfileCustomerDetailsCell}>
            <div className={styles.customerEditProfileCustomerDetailsInfo}>
              <FormInput
                className={styles.customerEditProfileFormField}
                label={locale.form.province}
                type="select"
                placeholder={locale.form.province}
                options={[
                  { value: 'AB', label: 'Alberta' },
                  { value: 'BC', label: 'British Columbia' },
                  { value: 'MB', label: 'Manitoba' },
                  { value: 'NB', label: 'New Brunswick' },
                  { value: 'NL', label: 'Newfoundland and Labrador' },
                  { value: 'NT', label: 'Northwest Territories' },
                  { value: 'NS', label: 'Nova Scotia' },
                  { value: 'NU', label: 'Nunavut' },
                  { value: 'ON', label: 'Ontario' },
                  { value: 'PE', label: 'Prince Edward Island' },
                  { value: 'QC', label: 'Quebec' },
                  { value: 'SK', label: 'Saskatchewan' },
                  { value: 'YT', label: 'Yukon' },
                ]}
                onChange={(e) => setProvince(e.target.value)}
                value={province}
                error={province.error}
                errorMsg={province.error ? locale.form.provinceError : ''}
              />
            </div>
          </div>
        </div>

        <div className={styles.customerEditProfileCustomerDetailsLine}>
          <div className={styles.customerEditProfileCustomerDetailsCell}>
            <div className={styles.customerEditProfileCustomerDetailsInfo}>
              <FormInput
                className={styles.customerEditProfileFormField}
                label={locale.form.postalCode}
                placeholder={locale.form.postalCode}
                value={postalCode.value}
                onChange={(e) => formValidators.exists(e.target.value, postalCode, setPostalCode)}
                error={postalCode.error}
                errorMsg={postalCode.error ? locale.form.postalCodeError : ''}
              />
            </div>
          </div>
        </div>

        <div className={styles.customerEditProfileCustomerActions}>
          <div className={styles.customerEditProfileCustomerActionsCell}>
            <button
              className={styles.customerEditProfileSubmitButton}
              onClick={() => {
                handleUpdateProfile();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerEditProfile;
