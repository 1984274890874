import React from 'react';
import styles from './check-box.module.scss';
import { redirectKeyPressHandler } from '../../libs/accessibility';

const FormCheckBox = (props) => {
  let {
    id,
    onChange,
    isChecked,
    label,
    isComponent,
    fullLine,
    checkboxStyle,
    lableTextStyle: labelTextStyle,
  } = props;
  if (fullLine === undefined) {
    fullLine = true;
  }
  if (checkboxStyle === undefined) {
    checkboxStyle = 'grey';
  }
  if (labelTextStyle === undefined) {
    labelTextStyle = 'normal';
  }

  let checkboxStyles = ` ${styles.checkboxLine}`;
  if (fullLine) {
    checkboxStyles += ` ${styles.checkboxFull}`;
  } else {
    checkboxStyles += ` ${styles.checkboxHalf}`;
  }
  if (checkboxStyle === 'grey') {
    checkboxStyles += ` ${styles.checkboxGreyBox}`;
  } else {
    checkboxStyles += ` ${styles.checkboxRedBox}`;
  }
  if (labelTextStyle === 'normal') {
    checkboxStyles += ` ${styles.checkboxLabelNormal}`;
  } else {
    checkboxStyles += ` ${styles.checkboxLabelItalic}`;
  }

  const handleClickTrigger = (e) => {
    e.target.click();
  };

  return (
    <div className={checkboxStyles}>
      <input name={id} id={id} type="checkbox" onChange={onChange} checked={isChecked} />
      {isComponent ? (
        <label
          role="button"
          tabIndex="0"
          htmlFor={id}
          onKeyPress={redirectKeyPressHandler(handleClickTrigger)}
        >
          {label}
        </label>
      ) : (
        <label
          role="button"
          tabIndex="0"
          htmlFor={id}
          dangerouslySetInnerHTML={{ __html: label }}
          onKeyPress={redirectKeyPressHandler(handleClickTrigger)}
        ></label>
      )}
    </div>
  );
};

export default FormCheckBox;
