// Libs.
import React, {useEffect, useState, useContext} from 'react';
import * as PropTypes from 'prop-types';
import {Link} from 'gatsby';
import {compact, get} from 'lodash';
// State.
import {useStateValue} from '../../store/state';
import formValidators from '../../libs/form/form-validator';
import {AppContext} from '../layout/AppLayout';
// Services.
import crmService from '../../services/crmClientService';
// Deps.
import FormInput from '../common/form-input';
import FormCheckBox from '../common/check-box';
import TyrCardInput from '../common/tyrCardInput';
import TyrCardPinInput, {TYR_CARD_FOR_PIN} from '../common/tyrCardPinInput';
import {ValidateCodeRegister} from '../app/validate-code-register';
// Assets.
import styles from './user-register.module.scss';
import locale from '../../locale/locale.json';
import pageAliases from '../../data/pageAliases';
import appAliases from '../../data/appAliases';
import FormLoader from '../common/loaders/form-loader';
import ButtonLoader from '../common/loaders/button-loader';
import awaitHandler from '../../libs/asyncHandler';
import {OpenModal} from '../../store/modal/action-creators';
import {modalTypes} from '../../store/modal/action-types';
import AppTermsConditions from '../app/app-terms-conditions';
import terms from '../../data/terms-and-conditions.json';
import AppHidden from '../app/common/app-hidden';
import AppShown from '../app/common/app-shown';
// Custom libs.
import {getCategorizedContent} from '../../libs/static-content/static-content-utils';
import {replacePatternWithComponent} from '../../libs/localeUtils';
import {redirectKeyPressHandler} from '../../libs/accessibility';
import {addNotification} from '../../libs/notify';
import navigate from '../../libs/navigate';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
// Hooks.
import NavigationDrawer, {useDrawer} from '../../hooks/useNavigationDrawer';
import getWindow from '../../libs/getWindow';
import {pushEvent} from '../../libs/dataLayer';
import {usePagesRegistration} from "../../hooks/usePagesRegistration";

import UserConsentModal from './user-consent-modal';

const UserRegister = ({ destination, token = '', drawer, onClose, currentPage = '' }) => {
  // App state
  const [state, dispatch] = useStateValue();
  const isApp = useContext(AppContext);
  const [drawers, navigateDrawer] = useDrawer();
  const {executeRecaptcha} = useGoogleReCaptcha();
  // Form states
  const [FirstName, setFirstName] = useState({value: '', error: false});
  const [LastName, setLastName] = useState({value: '', error: false});
  const [EmailAddress, setEmailAddress] = useState({value: '', error: false});
  const [phoneNumber, setPhoneNumber] = useState({value: '', error: false});
  const [PostalCode, setPostalCode] = useState({value: '', error: false});
  const [Password, setPassword] = useState({value: '', error: false});
  const [ConfirmPassword, setConfirmPassword] = useState({
    value: '',
    error: false,
  });
  const [emailOptIn, setEmailOptIn] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptTermsError, setAcceptTermsError] = useState('');
  // Tyr card elements/state
  const [tyrNumber, setTyrNumber] = useState('');
  const [tyrError, setTyrError] = useState(false);
  const [tyrValid, setTyrValid] = useState(false);
  const [tyrPinNumber, setTyrPinNumber] = useState('');
  const [tyrPinError, setTyrPinError] = useState(false);
  const [tyrPinValid, setTyrPinValid] = useState(false);
  const [formError, setFormError] = useState();
  const [emailAlreadyExists, setUserAlreadyExists] = useState(false);
  const userAlreadyExistsError = locale.form.emailAlreadyExists.split('[LINK]');
  const [submission, setSubmission] = useState(false);
  const params = getWindow('location');
  const labels = usePagesRegistration()

  const pageTitle = get(labels, 'configPagesRegistration.registrationTitle', locale.registrationPage.titles.pageTitle);
  const subtitle1 = get(labels, 'configPagesRegistration.registrationDescription', locale.registrationPage.titles.subtitle1);
  const optForOffersLabel = get(labels, 'configPagesRegistration.receiveEmailsLabel', locale.registrationPage.labels.optForOffersLabel);
  const acceptTermsLabel = get(labels, 'configPagesRegistration.agreementLabel', locale.registrationPage.labels.acceptTermsLabel);
  /**
   * Handle changes to the form to report any field erroring.
   */
  const checkRequired = (get, set) => {
    const error = get.error || !get.value;
    set({...get, error: error});
    return error;
  };
  const formHasErrors = () => {
    let errors = [];
    setAcceptTermsError('');

    // Enforce error checking
    errors.push(checkRequired(FirstName, setFirstName));
    errors.push(checkRequired(LastName, setLastName));
    errors.push(checkRequired(EmailAddress, setEmailAddress));
    errors.push(checkRequired(PostalCode, setPostalCode));
    errors.push(checkRequired(Password, setPassword));
    errors.push(checkRequired(ConfirmPassword, setConfirmPassword));

    if (Password.value !== ConfirmPassword.value) {
      setConfirmPassword({...ConfirmPassword, error: true});
      errors.push(true);
    }
    if (!acceptTerms) {
      errors.push(true);
      setAcceptTermsError(locale.form.termsError);
    }

    errors = !!compact(errors).length;
    // console.log('error check', errors);
    if (errors) {
      displayError(locale.registrationPage.errors.invalidFormFields);
    }
    return errors;
  };

  const handleRegister = (e) => {
    e.preventDefault();
    // consentModalWindow();
    if (submission) {
      return;
    }

    // Set submission
    setSubmission(true);
    // Clear form errors
    setFormError();

    // Check for errors
    if (!formHasErrors()) {
      // Was terms accepted? If so try API reg now.
      if (!acceptTerms) {
        displayError(locale.registrationPage.errors.acceptTermsError);
        setSubmission(false);
      } else {
        register();
      }
    } else {
      setSubmission(false);
    }
  };

  const displayError = (message) => {
    if (isApp) {
      addNotification(message, "error");
    } else {
      setFormError(message);
    }
  };

  const register = async (optInModalClose, optInEmail) => {
    // Check if tyr card is entered, and if so if it was valid.
    if (!tyrNumber || tyrValid) {
      // All is valid, start validation and registration.
      let user = {
        EmailAddress: EmailAddress.value,
        Password: Password.value,
        FirstName: FirstName.value,
        LastName: LastName.value,
        PrimaryAddress_PostalCode: PostalCode.value,
      };
      if (phoneNumber.value !== '') {
        user.PrimaryPhone = phoneNumber.value;
      }
      if (tyrNumber) {
        user.TyrCardNumber = tyrNumber;
      }

      if (tyrNumber && parseInt(tyrNumber.trim()) > TYR_CARD_FOR_PIN && (!tyrPinNumber || !tyrPinValid)) {
        setSubmission(false);
        return;
      } else if (tyrPinNumber && tyrPinValid && parseInt(tyrNumber.trim()) > TYR_CARD_FOR_PIN) {
        user.TYRCardPIN = tyrPinNumber;
      }
      if (emailOptIn || (optInModalClose && optInEmail)) {
        user.OptInEmail = emailOptIn || optInEmail;
      }

      if(!emailOptIn && !optInModalClose) {
        consentModalWindow();
        setSubmission(false);
        return;
      }

      const {search, pathname} = getWindow('location');
      const cleanSearchString = search.indexOf("?") >= 0 ? search.split("?")[1] : search;
      const urlSegments = cleanSearchString.split("&").filter(segment => segment.indexOf("drawer") < 0)

      const token = await executeRecaptcha('registration');
      crmService.setRecaptchaToken(token);
      // eslint-disable-next-line no-unused-vars
      crmService
        .customerCreate(user)
        .then(async () => {
          crmService.resetRecaptchaToken();
          setSubmission(false);
          pushEvent('Interaction', 'Register', `Successful registered.`, user.EmailAddress);
          if (isApp) {
            /*const token = await executeRecaptcha('registrationCode');
            crmService.setRecaptchaToken(token);
            crmService.registrationEmailTokenResend(EmailAddress.value).then(() => {
              crmService.resetRecaptchaToken();
            });*/

            // navigateDrawer('verifyAccountRegister');
            navigateDrawer(false);
            navigate(`${pathname}?${urlSegments.join("&")}&drawer=verifyAccountRegister&emailAddress=${EmailAddress.value}`)
          } else {
            navigate(pageAliases.thankyou, {
              state: {context: 'registration', userEmail: EmailAddress.value},
            });
          }
        })
        .catch(async (e) => {
          // const token = await executeRecaptcha('registration');
          // crmService.setRecaptchaToken(token);
          pushEvent('Interaction', 'Register', `Registration Failed`, user.EmailAddress);
          // User email already exist, provide notices for login/reset.
          if (e.requestStatus === 409) {
            setUserAlreadyExists(true);
            displayError(locale.registrationPage.errors.errorSubmitting);
          } else if (e.requestStatus === 400 && e.ResponseCode === "303") {
            setTyrPinError(true);
            displayError(locale.registrationPage.errors.errorSubmitting);
          } else {
            displayError(locale.registrationPage.errors.serverError);
          }
          setSubmission(false);
        });
    } else {
      setSubmission(false);
    }
  };

  const handleOptInModalClose = (optInEmail) => {
    console.log("Modal close", optInEmail);
    setSubmission(true);
    register(true, optInEmail);
  }

  useEffect(() => {
    setUserAlreadyExists(false);
  }, [EmailAddress.value]);

  useEffect(() => {
    // Display customer view when session start.
    if (!isApp && state.user.access_token && destination) {
      navigate(destination);
    }
  }, [state.user]);

  const confirmToken = async (emailToken) => {
    // Don't resubmit.
    if (submission) {
      return;
    }

    setSubmission(true);

    let token = await executeRecaptcha('registration');
    crmService.setRecaptchaToken(token);

    const [firstResult, firstErr] = await awaitHandler(crmService.emailTokenConfirm(emailToken));
    const responseCode = (firstResult || firstErr || {ResponseCode: null}).ResponseCode;


    if (responseCode === '000') {
      navigate(pageAliases.congratulations);
    } else if (['103', '104'].indexOf(responseCode) !== -1) {
      if (responseCode === '104') {
        navigate(pageAliases.expire, {
          state: {context: 'registration'},
        });
      } else {
        navigate(pageAliases.incomplete, {
          state: {expiredToken: emailToken, context: 'registration'},
        });
      }
    } else {
      navigate(pageAliases.incomplete, {state: {context: 'registration'}});
    }

    setSubmission(false);
  };

  useEffect(() => {
    if (token !== '') {
      const emailToken = {
        Token: token,
      };
      confirmToken(emailToken);
    }
  }, []);

  const elements = getCategorizedContent(terms);

  const consentModalWindow = () => {
    dispatch(
      OpenModal({
        id: 'consent-modal',
        type: modalTypes.SLIDEIN,
        content: (
          <UserConsentModal onClose={handleOptInModalClose} emailAddress={EmailAddress} />
        ),
      })
    );
  };
  const modalWindow = () => {
    dispatch(
      OpenModal({
        id: 'term-conditions',
        type: modalTypes.SLIDEIN,
        content: (
          <AppTermsConditions
            title={locale.termsAndConditions.title}
            items={elements}
            numItemsPerLine="1"
            viewAllLink=""
          />
        ),
      })
    );
  };

  const pattern = /(Terms and Conditions)/;
  const openModal = replacePatternWithComponent(
    locale.registrationPage.labels.appAcceptTermsLabel,
    pattern,
    <span onClick={modalWindow} onKeyPress={redirectKeyPressHandler(modalWindow)}>
      Terms and Conditions
    </span>
  );

  const closeDrawer = () => {
    navigate(`${appAliases.appRewards}?drawer=userLogin`);
    if (onClose) {
      // Removed avoid flicker effect.
      setTimeout(() => {
        onClose(false);
      }, 300);
    }
  };

  return (
    <>
      {token !== '' ? (
        <div className={styles.userRegisterBackground}>
          <div className={styles.userRegisterContainer}></div>
        </div>
      ) : (
        <div className={styles.userRegisterBackground}>
          <div className={styles.userRegisterContainer}>
            <div className={styles.userRegisterTitle}>
              {pageTitle}
            </div>
            <div className={styles.userRegisterSubtitle}>
              <span
                dangerouslySetInnerHTML={{
                  __html: subtitle1,
                }}
              ></span>

              {locale.registrationPage.titles.subtitle2 !== "" && <span
                dangerouslySetInnerHTML={{
                  __html: locale.registrationPage.titles.subtitle2,
                }}
              ></span>}
            </div>
            <div className={styles.userRegisterFormContainer}>
              <form className={styles.userRegisterForm} onSubmit={handleRegister}>
                {submission && <FormLoader/>}
                <fieldset>
                  <div className={styles.userRegisterFormLineContainer}>
                    <FormInput
                      className={styles.userRegisterFormLineHalf}
                      placeholder={locale.form.firstName}
                      value={FirstName.value}
                      onChange={(e) =>
                        formValidators.exists(e.target.value, FirstName, setFirstName)
                      }
                      error={FirstName.error}
                      errorMsg={FirstName.error && locale.form.firstNameError}
                    />
                    <FormInput
                      className={styles.userRegisterFormLineHalf}
                      placeholder={locale.form.lastName}
                      value={LastName.value}
                      onChange={(e) => formValidators.exists(e.target.value, LastName, setLastName)}
                      error={LastName.error}
                      errorMsg={LastName.error && locale.form.lastNameError}
                    />
                  </div>
                  <div className={styles.userRegisterFormLineContainer}>
                    <FormInput
                      className={styles.userRegisterFormLineHalf}
                      placeholder={locale.form.postalCode}
                      value={PostalCode.value}
                      onChange={(e) =>
                        formValidators.postalCode(e.target.value, PostalCode, setPostalCode)
                      }
                      error={PostalCode.error}
                      errorMsg={PostalCode.error && locale.form.postalCodeError}
                    />
                    <FormInput
                      className={styles.userRegisterFormLineHalf}
                      placeholder={locale.form.phone}
                      value={phoneNumber.value}
                      onChange={(e) =>
                        formValidators.phone(e.target.value, phoneNumber, setPhoneNumber)
                      }
                      error={phoneNumber.error}
                      errorMsg={phoneNumber.error && locale.form.phoneError}
                    />
                  </div>
                  <div className={styles.userRegisterFormLineContainer}>
                    <div className={styles.userRegisterFormLineHalf}>
                      <div className={styles.userRegisterFormLineHalf}>
                        <FormInput
                          placeholder={locale.form.email}
                          value={EmailAddress.value}
                          onChange={(e) =>
                            formValidators.email(e.target.value, EmailAddress, setEmailAddress)
                          }
                          error={EmailAddress.error}
                          errorMsg={EmailAddress.error && locale.form.emailError}
                        />
                      </div>
                      {emailAlreadyExists && (
                        <>
                          <AppHidden>
                            <div className={styles.userRegisterFormUserExists}>
                              {userAlreadyExistsError[0]}
                              <Link to={pageAliases.login}>Login</Link>
                              {userAlreadyExistsError[1]}
                              <Link to={`/reset-password`}>Reset Your Password</Link>
                              {userAlreadyExistsError[2]}
                            </div>
                          </AppHidden>
                          <AppShown>
                            <div className={styles.userRegisterFormUserExists}>
                              {userAlreadyExistsError[0]}
                              <span
                                style={{cursor: 'pointer', textDecoration: 'underline'}}
                                onClick={closeDrawer}
                              >
                                Login
                              </span>
                              {userAlreadyExistsError[1]}
                              <span
                                style={{cursor: 'pointer', textDecoration: 'underline'}}
                                onClick={() => {
                                  closeDrawer();
                                  setTimeout(() => {
                                    navigate(`/app/${currentPage}/?drawer=resetPassword`);
                                  }, 500);
                                }}
                              >
                                Reset Your Password
                              </span>
                              {userAlreadyExistsError[2]}
                            </div>
                          </AppShown>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={styles.userRegisterFormLineContainer}>
                    <div className={`${styles.userRegisterSubtitle} ${styles.spacer}`}>
                      <span>{locale.registrationPage.tooltips.tyr}</span>
                    </div>
                  </div>
                  <div className={styles.userRegisterFormLineContainer}>
                    <div className={styles.userRegisterFormLineHalf}>
                      <div className={styles.formTopFieldSpacer}>&nbsp;</div>
                      <TyrCardInput
                        placeholder={locale.form.tyrNumber.labelWithOptional}
                        state={{
                          value: {get: tyrNumber, set: setTyrNumber},
                          error: {get: tyrError, set: setTyrError},
                          valid: {get: tyrValid, set: setTyrValid},
                        }}
                        showCta={false}
                      />
                    </div>
                    <div className={styles.userRegisterFormLineOneThird}>
                      <TyrCardPinInput
                        placeholder={locale.form.tyrNumberPin.labelWithOptional}
                        state={{
                          cardNumber: {get: tyrNumber, set: setTyrNumber},
                          cardNumberValid: {get: tyrValid, set: setTyrNumber},
                          value: {get: tyrPinNumber, set: setTyrPinNumber},
                          error: {get: tyrPinError, set: setTyrPinError},
                          valid: {get: tyrPinValid, set: setTyrPinValid},
                        }}
                        showCta={true}
                      />
                    </div>
                  </div>
                  <div className={styles.userRegisterFormLineContainer}>
                    <FormInput
                      type="password"
                      className={styles.userRegisterFormLineHalf}
                      placeholder={locale.form.createPassword}
                      value={Password.value}
                      onChange={(e) =>
                        formValidators.password(e.target.value, Password, setPassword)
                      }
                      error={Password.error}
                      errorMsg={Password.error && locale.form.passwordError}
                      validMsg={locale.form.passwordFootnote}
                    />

                    <FormInput
                      type="password"
                      className={styles.userRegisterFormLineHalf}
                      placeholder={locale.form.confirmPassword}
                      value={ConfirmPassword.value}
                      onChange={(e) =>
                        formValidators.password(
                          e.target.value,
                          ConfirmPassword,
                          setConfirmPassword
                        )
                      }
                      error={ConfirmPassword.error}
                      errorMsg={ConfirmPassword.error && locale.form.confirmPasswordError}
                    />
                  </div>
                  <div className={styles.userRegisterFormLineContainer}>
                    <div className={styles.userRegisterFormPasswordContainer}>
                      <div className={`${styles.userRegisterTermstitle}`}>
                        <span>{locale.registrationPage.labels.termsTitle}</span>
                      </div>
                      <AppHidden>
                        <div className={styles.userRegisterFormOptInContainer}>
                          <FormCheckBox
                            id="optForOffersLabel"
                            onChange={() => {
                              setEmailOptIn(!emailOptIn);
                            }}
                            isChecked={emailOptIn}
                            label={`<b>${optForOffersLabel}</b>`}
                            fullLine={false}
                            checkboxStyle="red"
                          />
                        </div>

                        <div className={styles.userRegisterFormOptInContainer}>
                          <FormCheckBox
                            id="acceptTermsLabel"
                            onChange={() => {
                              setAcceptTerms(!acceptTerms);
                            }}
                            isChecked={acceptTerms}
                            label={acceptTermsLabel}
                            fullLine={false}
                            checkboxStyle="red"
                          />
                        </div>
                        {acceptTermsError !== '' && (
                          <div className={styles.userRegisterFormCheckboxError}>
                            {acceptTermsError}
                          </div>
                        )}
                      </AppHidden>
                      <AppShown>
                        <div className={styles.appUserRegisterFormOptInContainer}>
                          <FormCheckBox
                            id="optForOffersLabel"
                            onChange={() => {
                              setEmailOptIn(!emailOptIn);
                            }}
                            isChecked={emailOptIn}
                            label={`<b>${optForOffersLabel}</b>`}
                            fullLine={false}
                            checkboxStyle="red"
                          />
                        </div>

                        <div className={styles.appUserRegisterFormOptInContainer}>
                          <FormCheckBox
                            id="acceptTermsLabel"
                            onChange={() => {
                              setAcceptTerms(!acceptTerms);
                            }}
                            isComponent={true}
                            isChecked={acceptTerms}
                            label={openModal}
                            fullLine={false}
                            checkboxStyle="red"
                          />
                          {acceptTermsError !== '' && (
                            <div className={styles.userRegisterFormCheckboxError}>
                              {acceptTermsError}
                            </div>
                          )}
                        </div>
                      </AppShown>
                    </div>
                  </div>
                </fieldset>
                <button className={styles.userRegisterFormSubmit} type={'submit'}>
                  {locale.registrationPage.buttons.registerButton}
                  {submission && <ButtonLoader/>}
                </button>
                {submission && <div className={styles.userRegisterFormError}>{formError}</div>}
              </form>
            </div>
          </div>
          <NavigationDrawer
            header={
              get(params, 'pathname', '') === appAliases.appAccountProfile ? 'Account' : 'Rewards'
            }
            drawerStatus={drawers.includes('verifyAccountRegister')}
            menuHide={true}
          >
            <ValidateCodeRegister userEmail={EmailAddress.value} drawer={drawer}/>
          </NavigationDrawer>
        </div>
      )}
    </>
  );
};

UserRegister.propTypes = {
  destination: PropTypes.string,
  drawer: PropTypes.object,
};

export default UserRegister;